import { createRouter, createWebHashHistory } from 'vue-router';
import Merch from '../views/Merch.vue';
import Contact from '../views/Contact.vue';
import About from '../views/About.vue';
import Admin from '../views/Admin.vue';
import Shows from '../views/Shows.vue';
import Music from '../views/Music.vue';
import Home from '../views/Home.vue';
import ProductPage from '../views/ProductPage.vue';
import PaymentSuccess from '../views/PaymentSuccess.vue';
import Visuals from '../views/Visuals.vue';

const routes = [
  {
    path: '/attherocks',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/merch',
    name: 'Merch',
    component: Merch,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/music',
    name: 'Music',
    component: Music,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/shows',
    name: 'Shows',
    component: Shows,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/visuals',
    name: 'Visuals',
    component: Visuals,
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: ProductPage,
    props: (route) => ({
      productId: route.params.id
    }),
    beforeEnter: (to, from, next) => {
      document.querySelector('#content').scrollTo(0,0);
      next();
    },
  },
  {
    path: '/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess,
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes
})

export default router;
